/* Base styles (for desktops) */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Code font styling */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  body {
    /* Adjust styles for mobile */
    font-size: 14px; /* Example: smaller font size for mobile */
    padding: 10px; /* Example: add padding for mobile */
  }

  /* You can add additional mobile-specific styles here */
  code {
    font-size: 12px; /* Example: smaller code font size for mobile */
  }
}

/* Tablet and larger devices */
@media (min-width: 769px) {
  body {
    /* Adjust styles for tablets and larger devices */
    font-size: 16px; /* Example: standard font size for desktop */
  }

  code {
    font-size: 14px; /* Example: standard code font size for desktop */
  }
}
